import Styled from 'styled-components';

const StyledReadMoreButton = Styled.div`
  button {
    padding: 0;
    margin-top: 0.4rem;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    cursor: pointer;
    height: 2rem;

    justify-self: start;

    span { 
      font-size: ${props => props.theme.font.sizes.md};
    }
  }
`;

export default StyledReadMoreButton;